<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-08 15:18:04
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="resetpwd">
    <heade></heade>
    <div class="content">
        <div class="box">
            <p>请重置企业密码</p>
            <ul>
                <li>
                    <span>*</span>输入新密码<input :type="mima" placeholder="请输入密码（6-20位字母与数字）" v-model="pwd1" @blur="blu" @focus="focu" @keyup="keyu">
                    <img class="img1" src="../assets/Stroken2.png" alt="">
                    <i @click="yanjing"><img :src="suo?require('../assets/eye-no.png'):require('../assets/eye.png')" alt=""></i>
                    <s class="p animated bounceInRight" v-if="mima_cuo">请输入6-20位字母与数字的密码</s>
                </li>
                <li>
                    <span>*</span>确认新密码<input type="password" placeholder="请确认新密码" v-model="pwd2" @keyup="keyu">
                    <img class="img1" src="../assets/Stroken2.png" alt="">
                </li>
            </ul>
            <div :class="que?'que1':'que2'" @click="sure">确认</div>
        </div>
    </div>
    <foote></foote>
  </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
import { first_login_alter_ent_acct_pwd } from '../api/api.js'
export default {
  name: 'resetpwd',
  data () {
    return {
      que: true,
      suo: true,
      mima: 'password',
      pwd1: '',
      pwd2: '',
      mima_cuo: false,
      phone: ''
    }
  },
  mounted () {
    this.jichu()
  },
  components: { heade, foote },
  methods: {
    //   获取基础信息
    jichu () {
      if (sessionStorage.getItem('pc_mlbb_phone') != null) {
        this.phone = JSON.parse(sessionStorage.getItem('pc_mlbb_phone'))
      }
    },
    home () {
      this.$router.push('/')
    },
    // 点击眼睛
    yanjing () {
      this.suo = !this.suo
      if (this.suo) {
        this.mima = 'password'
      } else {
        this.mima = 'text'
      }
    },
    // input框事件
    blu () { // 失去焦点
      if (this.pwd1.length > 0) {
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd1)) {
          this.mima_cuo = true
        } else {
          this.mima_cuo = false
        }
      }
    },
    focu () { // 获取焦点
      this.mima_cuo = false
    },
    keyu () {
      // console.log(333)
      if (this.pwd1 != '' && this.pwd2 != '') {
        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(this.pwd1) && this.pwd1 == this.pwd2) {
          this.que = false
        } else {
          this.que = true
        }
      } else {
        this.que = true
      }
    },
    // 点击确认按钮
    sure () {
      if (this.que == false) {
        first_login_alter_ent_acct_pwd({
          data: {
            ent_acct: this.phone,
            ent_pwd: this.pwd1
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10021) {
            this.$message({
              message: '修改成功，请重新登录',
              type: 'success'
            })
            this.$router.push('/enterprise')
          } else if (res.data.code == 10022) {
            this.$message({
              message: '修改失败',
              type: 'error'
            })
          }
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.resetpwd {
  background: #E7E8EB;
  min-height: 100vh;
  .content {
    height: 100%;
    width: 100%;
    padding-top:1.83rem;
    margin-bottom:1.94rem;
    .box{
        height:4.49rem;
        width: 9.6rem;
        background: #fff;
        border-radius: 0.04rem;
        margin: 0 auto;
        p{
            height:0.7rem;
            background:#1A2533;
            color: #FFFFFF;
            font-size: 0.22rem;
            line-height: 0.7rem;
            padding: 0 0.5rem;
            margin-bottom: 0.58rem;
        }
        ul{
            li{
                margin:0 auto;
                width: 42%;
                border:0.01rem solid transparent;
                margin-bottom: 0.38rem;
                position: relative;
                span{
                    float: left;
                    color: #E82716;
                    font-size:0.16rem;
                    line-height: 0.38rem;
                }
                font-size: 0.16rem;
                color:#676669;
                line-height: 0.38rem;
                input{
                    width:2.51rem;
                    height:0.38rem;
                    border:0.01rem solid #DDDDDD;
                    border-radius: 0.06rem;
                    float: right;
                    padding-left: 0.45rem;
                }
                .img1{
                    position: absolute;
                    left: 1.27rem;
                    height:0.2rem;
                    width:0.2rem;
                    top:0.09rem;
                }
                i{
                    padding: 0 0.1rem 0 0.14rem;
                    border-left: 0.01rem solid #ddd;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right:0;
                    top:0.13rem;
                    img{
                        height:0.16rem;
                        width:0.2rem;
                    }
                }
                .p{
                    font-size: 0.12rem;
                    position: absolute;
                    display: inline;
                    bottom: -0.12rem;
                    right: 0;
                    line-height: 0.12rem;
                    color: #E6A23C;
                }
            }
        }
        div{
            height:0.38rem;
            width:2.86rem;
            border-radius: 0.06rem;
            margin:0 auto;
            margin-top:0.66rem;
            font-size: 0.16rem;
            color:#fff;
            line-height: 0.38rem;
            text-align: center;
        }
        .que1{
            background: #b8c1ce;
        }
        .que2{
            background:linear-gradient(270deg,rgba(251,112,112,1) 0%,rgba(230,80,80,1) 100%);
        }
    }
  }
}
</style>
